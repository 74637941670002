const commonMethods = {
    // 显示删除按钮
    showDelBtn(row) {
        return row.hasOwnProperty('Sfksc') ? row.Sfksc === 0 : true;
    },
    // 显示编辑按钮
    showEditBtn(row) {
        // return row.hasOwnProperty('Sfksc') ? row.Sfksc === 0 : true;
        return true;
    },
    // 显示查看按钮
    showSeeBtn(row) {
        // return !this.showEditBtn(row);
        return false;
    },
};
export { commonMethods };