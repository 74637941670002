import alertInfo from '@comp/alert_info';
import basicUpload from '@comp/basic_upload';
import { mapGetters, mapActions } from 'vuex';
import { commonMethods } from './table-common-methods.js';
export default {
    props: {
        menus: Array
    },
    data() {
        return {
            commonMethods,
            tableGroupAttributes: {},
            multipleSelection: [],
            tabheight: null,
            searchData: [],
            total: 10, // 每页显示几条数据
        };
    },
    mounted() {
        this.handleDataAll(this.selfData, this.commonData);
        this.tableGroupAttributes = this.selfData;
        let search = this.tableGroupAttributes.snSearch.selectData || [];
        if (search.length > 0) {
            search.forEach(item => {
                let type = item.value;
                this.searchData.push({ type: type, value: '' });
            });
        }
        this.tableGroupAttributes.snPage.currentPage = 1;
        this.getTableData();
    },
    methods: {
        ...mapActions([
            'getUserList'
        ]),
        handleDataAll(self, common) {
            if (Array.isArray(common)) return;
            Object.keys(common).forEach(key => {
                if (self.hasOwnProperty(key)) {
                    if (typeof common[key] !== 'object') return false;
                    if (key === 'snButton') return false;
                    this.handleDataAll(self[key], common[key]);
                } else {
                    self[key] = common[key];
                }
            });
        },
        handleChange(type, value) {
            console.log(type, value);
            switch (type) {
                case 'search': // 搜索
                    this[value[1]] = value[0];
                    this.searchData.forEach(item => {
                        if (item.type === value[1]) {
                            item.value = value[0];
                        }
                    });
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case 'total': // 每页的条数
                    this[value[1]] = value[0];
                    this.tableGroupAttributes.snPage.tiaoshu = value[0];
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case 'page': // 当前是第几页
                    this.tableGroupAttributes.snPage.currentPage = value[0];
                    this.getTableData();
                    break;
                case 'searchBtn': // 点击查询按钮
                    this.getTableData();
                    break;
                case 'buttonDel': // 批量删除按钮
                    this.delSome();
                    break;
                case 'buttonNew': // 新建按钮
                    this.toEditPage();
                    break;
                case 'buttonExport': // 导出按钮
                    this.exportData();
                    break;
                case 'columnEdit': // 编辑按钮
                    this.toEditPage(value);
                    break;
                case 'columnSee': // 查看按钮
                    this.toEditPage(value, true);
                    break;
                case 'columnDelete': // 删除按钮
                    this.deleteData(value[0].Id ? value[0].Id : value[0].ID); // TODO id
                    break;
                case 'selection': // 表格选择框
                    this.selectData(value[0]);
                    break;
                default:
                    break;
            }
        },
        toEditPage(value, onlySee) {
            let id = value && value.length > 0 ? value[0].Id ? value[0].Id : value[0].ID : '';
            let name = id ? '编辑' : '新建';
            console.log(name, '去子页面', this.infoPage);
            this.$router.push({
                name: this.infoPage,
                params: {
                    newdoc: id ? 'bianji' : 'xinjian',
                    strId: id || 'wu',
                    canEdit: !onlySee
                }
            });
        },
        exportData() {
            if (!this.exportItemId) return;
            const currentItem = this.exportItemId;
            let menu = this.menus.find(item => item.routerName == currentItem);
            let name1 = '';
            name1 = menu && menu.name ? menu.name : "";
            let name = name1 + '列表.xls';
            let obj = {
                url:
                    window.REQUEST_URL +
                    "GatherPart/ExportBasicData",
                name: name,
                para: {
                    itemId: currentItem
                },
            };
            this.common.exportFile(obj);
        },
        deleteData(value) {
            let _this = this;
            let checkBox = value;
            _this
                .$confirm('是否永久删除该条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            _this.delData(checkBox, done, instance);
                        } else {
                            done();
                        }
                    }
                })
                .then(() => { })
                .catch(() => {
                    _this.$message({
                        message: '已取消删除',
                        type: 'info'
                    });
                });
        },
        delSome() {
            let checkBox = [];
            this.multipleSelection.forEach(function (o) {
                checkBox.push(o.ID ? o.ID : o.Id); // TODO id
            });
            if (checkBox.length === 0) {
                this.$message({
                    message: '请选择数据项！',
                    type: 'warning'
                });
            } else {
                checkBox = checkBox.join();
                this.deleteData(checkBox);
            }
        },
        delOver(res, done) {
            if (res) {
                done();
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.tableGroupAttributes.snPage.currentPage = 1;
                this.getTableData();
            } else {
                this.$message({
                    message: '删除失败',
                    type: 'error'
                });
            }
        },
        selectData(value) {
            this.multipleSelection = [];
            if (value && value.length > 0) {
                value.forEach(item => {
                    this.multipleSelection.push(item);
                });
            }
        }
    }
};