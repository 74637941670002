const commonData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 选择、序号
            columnsOperate: [{
                isShow: true,
                type: 'selection',
                width: 45
            }, {
                isShow: true,
                label: '序号',
                type: 'index',
                width: 50
            }],
            // 数据列
            columnsData: [],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    showMethod: 'showEditBtn',
                    btnType: 'icon',
                    operateType: 'columnEdit',
                    title: '编辑',
                    icon: 'el-icon-edit',
                    color: 'green'
                }, {
                    isShow: true,
                    showMethod: 'showSeeBtn',
                    btnType: 'icon',
                    operateType: 'columnSee',
                    title: '查看',
                    icon: 'el-icon-view',
                    color: 'green'
                }, {
                    isShow: true,
                    showMethod: 'showDelBtn',
                    btnType: 'icon',
                    operateType: 'columnDelete',
                    title: '删除',
                    icon: 'el-icon-delete',
                    color: 'red'
                }]
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snCount: {
        eachtotal: [20, 50, 100],
        defaultTiaoshu: 20
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true
        }],
        // searchBtn: {
        //     buttonData: [{
        //         btnType: 'button',
        //         operateType: 'searchBtn',
        //         name: '查询',
        //         round: true,
        //         backColor: '#2a63d5',
        //         color: '#fff'
        //     }]
        // }
    },
    snButton: {
        buttonData: [
        // {
        //     isShow: true,
        //     btnType: 'button',
        //     operateType: 'buttonDel',
        //     name: '批量删除(测试用)',
        //     round: true,
        //     backColor: '#fa6f1b',
        // }, 
        {
            isShow: true,
            btnType: 'button',
            operateType: 'buttonNew',
            name: '新建',
            round: true,
            backColor: '#28ccd9',
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { commonData };