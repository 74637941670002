<template>
    <div class="tableBox">
        <sn-table-group :tableGroupAttributes="tableGroupAttributes" :commonMethods.sync="commonMethods"  @handleChange="handleChange"></sn-table-group>
    </div>
</template>
<script>

import { commonData } from '../sn-table-group-common';
import { selfData } from './sn-table-group-department';
import commonMethodMixin from '../commonMethodMixin';
import { mapActions } from 'vuex';
export default {
    name: 'department',
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            selfData,
            infoPage: 'departmentinfo',
            // downUrl: DOWN_URL + '/api/FileDownLoad/OnPostExport?funId=0002',
            keyword: '', // 关键字
            exportItemId: 'department'
        };
    },
    mounted() {
        console.log(this.selfData);
    },
    methods: {
        ...mapActions([
            'getDepList',
            'deleteDep'
        ]),
        async getTableData() {
            let res = await this.getDepList({
                keyword: this.keyword,
                pageindex: this.tableGroupAttributes.snPage.currentPage,
                sum: this.tableGroupAttributes.snPage.tiaoshu
            });
            this.tableGroupAttributes.snTable.table.data = res.Data;
            this.tableGroupAttributes.snPage.count = Number(res.Total);
            this.tableGroupAttributes.loading = false;
        },
        // 删除数据
        async delData(id, done) {
            let res = await this.deleteDep({
                ids: id
            });
            this.delOver(res, done);
        },
    }
};
</script>