const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'Id',
        },
        columns: {
            // 数据列
            columnsData: [{
                    label: "部门名称",
                    prop: "Name"
                },
                {
                    label: "部门职责",
                    prop: "Description"
                },
                {
                    label: "入库时间",
                    prop: "Rksj"
                }
            ]
        },
    }
};
export { selfData };